<template>
  <div>
    <a id="youtube-block" class="relative" href="#" style=" top: -85px" />
    <SectionTitle hideBtn title="Youtube連結" />
    <BaseTable :data="banners">
      <BaseElTableColumn align="center" label="連結" prop="url" />
    </BaseTable>
  </div>
</template>

<script>
import { get } from 'lodash'

export default {
  name: 'YoutubeViewBlock',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    banners () {
      return [get(this.data, 'youtube', {})]
    },
  },
}
</script>
