<template>
  <div>
    <a id="textStory-block" class="relative" href="#" style=" top: -85px" />
    <SectionTitle hideBtn title="文字故事" />
    <BaseTable :data="banners">
      <BaseElTableColumn label="主標" prop="title" align="center" />
      <BaseElTableColumn label="簡述" prop="description" align="center" />
    </BaseTable>
  </div>
</template>

<script>
import { get } from 'lodash'

export default {
  name: 'TextStoryViewBlock',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    banners () {
      return [get(this.data, 'text', {})]
    },
  },
}
</script>
